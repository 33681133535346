import React, {
  FunctionComponent,
  useState,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { Box, Heading, Text } from '@wix/design-system';
import { useRouter } from 'next/router';
import { Article } from '@wix/answers-api';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { InnerLink } from '../InnerLink';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { BI } from '../../constants';
import { mapPageTypeToSourceName } from '../../utils/bi';
import { PageType } from '../../types';
import { ArticleMediumIcon } from '../../icons/ArticleMediumIcon';
import css from './index.module.scss';

export type RelatedArticlesProps = {
  itemId: string;
  itemType: string;
  relatedArticles: Article[];
  pageType: PageType;
};

export const RelatedArticles: FunctionComponent<RelatedArticlesProps> = ({
  itemId,
  itemType,
  relatedArticles,
  pageType,
}: RelatedArticlesProps) => {
  const { sendBIEvent } = useBI();
  const { t } = useTranslation();
  const { locale } = useRouter();

  const [hoveredRelatedArticle, setHoveredRelatedArticle] = useState('');

  const relatedArticleClick = async (
    event: MouseEvent | KeyboardEvent,
    article: Article,
    order: string
  ) => {
    const text = (event.target as HTMLElement).textContent as string;
    await sendBIEvent(
      pageClick({
        source_name: mapPageTypeToSourceName(pageType),
        kb_lang: locale as string,
        item_id: itemId,
        item_type: itemType,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.RELATED_CONTENT,
        clicked_url: `/${locale}${article.uri}`,
        clicked_text: text,
        clicked_item_order: order,
        clicked_item_id: article.id,
        is_page_content_click: false,
      })
    );
  };

  return relatedArticles && relatedArticles.length > 0 ? (
    <>
      <Box className={css.wrapper} direction="vertical">
        <Heading size="medium" as="h2" className={css.title}>
          {t('related.articles.title')}
        </Heading>
        <Box direction="horizontal" className={css.relatedArticles}>
          {relatedArticles.map((relatedArticle, index) => (
            <InnerLink
              key={relatedArticle.id}
              href={relatedArticle.uri}
              onMouseEnter={() => setHoveredRelatedArticle(relatedArticle.id)}
              onMouseLeave={() => setHoveredRelatedArticle('')}
              className={css.relatedArticle}
              dataHook={DATA_HOOKS.RELATED_ARTICLE_LINK}
              onClick={(e) =>
                relatedArticleClick(e, relatedArticle, `${index + 1}`)
              }
            >
              <Box gap="6px" verticalAlign="top">
                <ArticleMediumIcon />
                <Box className={css.relatedArticleTitleWrapper}>
                  <Text
                    size="small"
                    disabled
                    className={`${css.relatedArticleTitle} ${
                      hoveredRelatedArticle === relatedArticle.id &&
                      css.relatedArticleTitleHovered
                    }`}
                    dataHook={DATA_HOOKS.RELATED_ARTICLE_TEXT}
                  >
                    {relatedArticle.title}
                  </Text>
                </Box>
              </Box>
            </InnerLink>
          ))}
        </Box>
      </Box>
    </>
  ) : null;
};
