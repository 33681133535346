import { ArticleResolution, ArticleType } from '@wix/answers-api';
import {
  Box,
  FloatingNotification,
  FloatingNotificationType,
  Text,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export type ArticleStatusBannerProps = {
  articleResolution?: ArticleResolution;
  articleType: ArticleType;
};

export const ArticleStatusBanner: FunctionComponent<
  ArticleStatusBannerProps
> = ({ articleResolution, articleType }) => {
  const { t } = useTranslation();

  const getTitle = () => {
    switch (articleType) {
      case ArticleType.FEATURE_REQUEST:
        return t('feature.request.title');
      case ArticleType.KNOWN_ISSUE:
        return t('known.issue.title');
      default:
        return '';
    }
  };

  const isResolved =
    articleResolution === ArticleResolution.RELEASED ||
    articleResolution === ArticleResolution.RESOLVED;

  const workedOn =
    articleResolution === ArticleResolution.IN_DEVELOPMENT ||
    articleResolution === ArticleResolution.WORKING_ON_IT;

  const translationPrefix =
    articleType === ArticleType.KNOWN_ISSUE ? 'known-issue' : 'feature.request';

  const resolutionsI18nKeysMap: Record<number, string> = {
    [ArticleResolution.COLLECTING_VOTES]:
      'feature.request.collecting.vote.description',
    [ArticleResolution.PLANNED]: 'feature.request.planned.description',
    [ArticleResolution.RELEASED]: 'feature.request.released.description',
    [ArticleResolution.WORKING_ON_IT]: `${translationPrefix}.working.on.it.description`,
    [ArticleResolution.IN_DEVELOPMENT]: `${translationPrefix}.working.on.it.description`,
    [ArticleResolution.PENDING_RELEASE]:
      'feature.request.coming.soon.description',
    [ArticleResolution.COMING_SOON]: 'feature.request.coming.soon.description',
    [ArticleResolution.INVESTIGATING]: 'known-issue.investigating.description',
    [ArticleResolution.RESOLVED]: 'known-issue.resolved.description',
    [ArticleResolution.THIRD_PARTY_BUG]:
      'known-issue.third.party.bug.description',
  };

  const getType = (): FloatingNotificationType => {
    if (isResolved) {
      return 'success';
    } else if (articleType === ArticleType.KNOWN_ISSUE && !workedOn) {
      return 'destructive';
    } else {
      return 'warning';
    }
  };

  const text = (
    <Box gap="5px" direction="horizontal" verticalAlign="middle">
      <Text size="small" weight="bold" dataHook={DATA_HOOKS.ARTICLE_TYPE_TITLE}>
        {getTitle()}
      </Text>
      <Text size="small" weight="thin">
        |
      </Text>
      <Text
        size="small"
        weight="thin"
        dataHook={DATA_HOOKS.ARTICLE_TYPE_DESCRIPTION}
        className={css.articleResolutionText}
      >
        {t(articleResolution ? resolutionsI18nKeysMap[articleResolution] : '')}
      </Text>
    </Box>
  );

  return (
    <FloatingNotification
      dataHook={DATA_HOOKS.ARTICLE_STATUS_BANNER}
      className={`${css.articleResolutionBanner} type-${getType()}`}
      showCloseButton={false}
      type={getType()}
      text={text}
    />
  );
};
