import { Text } from '@wix/design-system';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import Long from 'long';
import { KI_PUBLISH_DATE_FORMAT } from '../../../constants';

export type ArticlePublishedDateProps = {
  locale: string;
  creationDate: number | Long;
};

const isLong = (obj: any): obj is Long =>
  obj.low !== undefined && obj.high !== undefined;

export const ArticlePublishedDate: FunctionComponent<
  ArticlePublishedDateProps
> = ({ creationDate, locale }: ArticlePublishedDateProps) => {
  const [date, setDate] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const lastPublishedDateNormalized = !isLong(creationDate)
      ? creationDate
      : new Long(creationDate.low, creationDate.high).toNumber();

    setDate(
      moment
        .unix(Math.floor(lastPublishedDateNormalized / 1000))
        .locale(locale)
        .format(KI_PUBLISH_DATE_FORMAT)
    );
  }, [creationDate, locale]);

  return (
    <Text
      dataHook={DATA_HOOKS.ARTICLE_LAST_PUBLISHED_DATE}
      size="small"
      weight="thin"
      className={css.articlePublishedDate}
    >
      {t('article.published', { date })}
    </Text>
  );
};
