import { wasItHelpfulView as helpCenterItemOnPageView } from '@wix/bi-logger-new-help-center/v2';
import { useRouter } from 'next/router';
import * as React from 'react';
import { BI } from '../constants';
import { useBI } from './useBI';
import { useOnVisible } from './useOnVisible';

export enum ItemOnPageEventSource {
  HELPFUL_VOTE_OPTION = 'HELPFUL_VOTE_OPTION',
  TRENDING_ARTICLES = 'TRENDING_ARTICLES',
  TOPICS_SECTION = 'TOPICS_SECTION',
  PRODUCT_ROADMAP = 'PRODUCT_ROADMAP',
  EXPLORE_MORE_RESOURCES_SECTION = 'EXPLORE_MORE_RESOURCES_SECTION',
  STUDIO_COMMUNITY_FORUM_ENTRY_HOME = 'STUDIO_COMMUNITY_FORUM_ENTRY_HOME',
  STUDIO_COMMUNITY_FORUM_ENTRY_ARTICLE = 'STUDIO_COMMUNITY_FORUM_ENTRY_ARTICLE',
  STUDIO_COMMUNITY_WISHLIST_ENTRY = 'STUDIO_COMMUNITY_WISHLIST_ENTRY',
  FEATURE_REQUEST_FOLLOW = 'FEATURE_REQUEST_FOLLOW',
  HIRE_A_PARTNER = 'HIRE_A_PARTNER',
  MARKETPLACE_ENTRY = 'MARKETPLACE_ENTRY',
  BLOG_SECTION = 'WIX_BLOG'
}

const eventSourceToItemName: Record<ItemOnPageEventSource, string> = {
  [ItemOnPageEventSource.HELPFUL_VOTE_OPTION]:
    BI.ITEM_NAMES.HELPFUL_VOTE_OPTION,
  [ItemOnPageEventSource.TRENDING_ARTICLES]: BI.ITEM_NAMES.TRENDING_ARTICLES,
  [ItemOnPageEventSource.TOPICS_SECTION]: BI.ITEM_NAMES.TOPICS_SECTION,
  [ItemOnPageEventSource.PRODUCT_ROADMAP]: BI.ITEM_NAMES.PRODUCT_ROADMAP,
  [ItemOnPageEventSource.EXPLORE_MORE_RESOURCES_SECTION]:
    BI.ITEM_NAMES.EXPLORE_MORE_RESOURCES_SECTION,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_HOME]:
    BI.ITEM_NAMES.STUDIO_COMMUNITY_FORUM,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_ARTICLE]:
    BI.ITEM_NAMES.STUDIO_COMMUNITY_FORUM,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_WISHLIST_ENTRY]:
    BI.ITEM_NAMES.COMMUNITY_WISHLIST,
  [ItemOnPageEventSource.FEATURE_REQUEST_FOLLOW]:
    BI.ITEM_NAMES.VOTE.FEATURE_REQUEST_FOLLOW,
  [ItemOnPageEventSource.HIRE_A_PARTNER]: BI.ITEM_NAMES.HIRE_A_PARTNER,
  [ItemOnPageEventSource.MARKETPLACE_ENTRY]: BI.ITEM_NAMES.HIRE_A_PARTNER,
  [ItemOnPageEventSource.BLOG_SECTION]: BI.ITEM_NAMES.BLOG,
};

const eventSourceToSourceName: Record<ItemOnPageEventSource, string> = {
  [ItemOnPageEventSource.HELPFUL_VOTE_OPTION]: BI.SOURCE_NAMES.ARTICLE,
  [ItemOnPageEventSource.TRENDING_ARTICLES]: BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.TOPICS_SECTION]: BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.PRODUCT_ROADMAP]: BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.EXPLORE_MORE_RESOURCES_SECTION]: BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_HOME]:
    BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_ARTICLE]:
    BI.SOURCE_NAMES.ARTICLE,
  [ItemOnPageEventSource.STUDIO_COMMUNITY_WISHLIST_ENTRY]:
    BI.SOURCE_NAMES.ARTICLE,
  [ItemOnPageEventSource.FEATURE_REQUEST_FOLLOW]: BI.SOURCE_NAMES.ARTICLE,
  [ItemOnPageEventSource.HIRE_A_PARTNER]: BI.SOURCE_NAMES.ARTICLE,
  [ItemOnPageEventSource.MARKETPLACE_ENTRY]: BI.SOURCE_NAMES.HOME,
  [ItemOnPageEventSource.BLOG_SECTION]: BI.SOURCE_NAMES.HOME,
};

export const useBiOnVisibleItem = <T extends HTMLElement = HTMLDivElement>(
  source: ItemOnPageEventSource,
  eventParams?: Pick<
    Parameters<typeof helpCenterItemOnPageView>[0],
    'item_id' | 'item_name' | 'feedback_details'
  >
) => {
  const itemRef = React.useRef<T>(null);
  const [isEventSent, setIsEventSent] = React.useState(false);

  const { sendBIEvent } = useBI();
  const isVisible = useOnVisible(itemRef);
  const { locale } = useRouter();

  React.useEffect(() => {
    setIsEventSent(false);
  }, [eventParams?.item_id]);

  const sendItemOnPageEvent = React.useCallback(async () => {
    await sendBIEvent(
      helpCenterItemOnPageView({
        kb_lang: locale,
        item_name: eventSourceToItemName[source],
        source_name: eventSourceToSourceName[source],
        ...eventParams,
      })
    );
    setIsEventSent(true);
  }, [sendBIEvent, locale, source, eventParams]);

  React.useEffect(() => {
    if (isVisible && !isEventSent) {
      sendItemOnPageEvent().catch(() => 0);
    }
  }, [isVisible, isEventSent, sendItemOnPageEvent]);

  const cancelItemOnPageEvent = React.useCallback(() => {
    setIsEventSent(true);
  }, []);

  const resetItemOnPageEvent = React.useCallback(() => {
    setIsEventSent(false);
  }, []);
  return {
    cancelItemOnPageEvent,
    resetItemOnPageEvent,
    itemRef,
  };
};
