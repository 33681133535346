import { ArticleResolution, ArticleType } from '@wix/answers-api';
import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { TimerSmall } from '@wix/wix-ui-icons-common';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../dataHooks';
import { RoadmapResolutionEta } from '../RoadmapResolutionEta';
import { ArticleStatusBanner } from './ArticleStatusBanner';
import css from './index.module.scss';
import { ArticlePublishedDate } from './ArticlePublishedDate';

export type ArticleHeaderProps = {
  type: ArticleType;
  title: string;
  creationDate: number | Long;
  locale: string;
  resolution?: ArticleResolution;
  resolutionEta?: Long;
  isRoadmapArticle?: boolean;
  readingTime?: number;
  onClickStatus?: (resolution: ArticleResolution) => void;
};

export const ArticleHeader: FunctionComponent<ArticleHeaderProps> = ({
  type,
  resolution,
  resolutionEta,
  title,
  isRoadmapArticle,
  readingTime,
  creationDate,
  locale,
  onClickStatus,
}: ArticleHeaderProps) => {
  const { t } = useTranslation();

  const showReadingTime =
    type === ArticleType.ARTICLE && readingTime && readingTime > 0;

  return (
    <>
      <Heading size="extraLarge" className={css.articleTitle}>
        {title}
      </Heading>

      {type === ArticleType.KNOWN_ISSUE ?
        <ArticlePublishedDate locale={locale} creationDate={creationDate} />
        : null}

      {isRoadmapArticle && resolution ? (
        <div className={css.articleRoadmapTags}>
          <RoadmapResolutionEta
            wrapEtaWithTag={true}
            resolution={resolution}
            resolutionEta={resolutionEta}
            onClickStatus={onClickStatus}
          />
        </div>
      ) : (
        [ArticleType.FEATURE_REQUEST, ArticleType.KNOWN_ISSUE].includes(type) &&
        <ArticleStatusBanner
          articleResolution={resolution}
          articleType={type}
        />
      )}

      {showReadingTime ? (
        <Box className={css.timeToReadWrapper}>
          <TimerSmall color={'#868AA5'} />
          <Text
            dataHook={DATA_HOOKS.ARTICLE_READING_TIME}
            size="small"
            color="D40"
            className={css.timeToRead}
          >
            {t('informative-article-time-to-read', { time: readingTime })}
          </Text>
        </Box>
      ) : null}
    </>
  );
};
