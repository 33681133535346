import { Article } from '@wix/answers-api';
import { Box } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { PAGES } from '../../constants';
import { Step } from '../../pages/article/[articleTitle]';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { RelatedArticles } from '../RelatedArticles';
import { HirePartner } from './HirePartner';
import { ScrollSpy } from './ScrollSpy';
import css from './index.module.scss';
import { StudioEntryBanner } from './StudioEntryBanner';

type ArticleSideBarProps = {
  article: Article;
  steps: Step[];
  isWixStudioUser: boolean;
  relatedArticles: Article[];
};

export const ArticleSideBar: FunctionComponent<ArticleSideBarProps> = ({
  article,
  steps,
  isWixStudioUser,
  relatedArticles,
}: ArticleSideBarProps) => (
  <Box direction="vertical" className={css.wrapper}>
    <ScrollSpy
      articleId={article.id}
      articleType={article.type}
      steps={steps}
    />
    <RelatedArticles
      itemId={article.id}
      itemType={mapArticleTypeToItemType(article.type)}
      relatedArticles={relatedArticles}
      pageType={PAGES.ARTICLE}
    />
    {isWixStudioUser ? (
      <StudioEntryBanner articleId={article.id} articleType={article.type} />
    ) : (
      <HirePartner articleId={article.id} categoryId={article.categoryId} />
    )}
  </Box>
);
