import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ArticleResolution, ArticleType } from '@wix/answers-api';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';
import { RenderHTML } from '../RenderHTML';
import { ArticleSearchResultItem, VoteType } from '../../types';
import { Vote } from '../Vote';
import css from './index.module.scss';
import { ArticleFeedback } from '../ArticleFeedback';
import { Context } from '../../context';
import { Box } from '@wix/design-system';
import { useVoteType } from '../../hooks/useVoteType';
import { EXPERIMENTS } from '../../constants';

type ArticleFooterProps = {
  article: ArticleSearchResultItem;
  isRoadmapArticle?: boolean;
  hasNoContent?: boolean;
};

export const ArticleFooter: FunctionComponent<ArticleFooterProps> = ({
  article,
  isRoadmapArticle,
  hasNoContent,
}: ArticleFooterProps) => {
  const { t } = useTranslation();
  const [isOptedIn, setIsOptedIn] = useState(false);
  const [isOptInStatusLoading, setIsOptInStatusLoading] = useState(false);
  const voteType = useVoteType(article);
  const { answersApi } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });

  const isSidebarOnLeftSideEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_SIDEBAR_ON_LEFT_SIDE);

  const toggleIsOptedIn = () => {
    setIsOptedIn(!isOptedIn);
  };

  useEffect(() => {
    void (async () => {
      if (voteType === VoteType.ENROLLMENT && article.featureEnrollment?.featureId) {
        setIsOptInStatusLoading(true);
        const { inPopulation } = await answersApi.kb.getPopulationStatus({
          populationId: article.featureEnrollment.featureId,
        });
        setIsOptedIn(inPopulation);
        setIsOptInStatusLoading(false);
      }
    })();
  }, [article.id]);

  if (article.type === ArticleType.ARTICLE) {
    return (
      <ArticleFeedback
        articleId={article.id}
        categoryId={article.categoryId}
        key={article.id}
      />
    );
  }

  return (
    <Box
      direction="vertical"
      className={classNames(css.articleFooter, {
        [css.roadmapArticleFooter]: isRoadmapArticle,
        [css.hasNoContent]: hasNoContent,
        [css.sideBarOnLeftSide]: isSidebarOnLeftSideEnabled,
      })}
    >
      <Vote
        article={article}
        isOptedIn={isOptedIn}
        toggleIsOptedIn={toggleIsOptedIn}
        isOptInStatusLoading={isOptInStatusLoading}
        hasNoContent={hasNoContent}
      />
      {isRoadmapArticle && (
        <RenderHTML
          textElement={{
            size: 'tiny',
            weight: 'thin',
            className: classNames(css.featureRequestNoteMark, {
              [css.featureRequestNoteMarkWithDivider]:
                article.resolution === ArticleResolution.RELEASED,
            }),
          }}
          className={css.NumberOfResultsMobile}
          html={t('roadmap.article-list.note-mark')}
        />
      )}
    </Box>
  );
};
