import { ArticleHelpfulState } from '@wix/answers-api';
import { Box, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export type VotingVariationAProps = {
  vote: ArticleHelpfulState;
  onVote: (vote: ArticleHelpfulState) => void;
};

export const VotingVariationA: FunctionComponent<VotingVariationAProps> = ({
  vote,
  onVote,
}: VotingVariationAProps) => {
  const { t } = useTranslation();

  const isPositiveFeedbackSent = vote === ArticleHelpfulState.HELPFUL;
  const isNegativeFeedbackSent = vote === ArticleHelpfulState.NOT_HELPFUL;
  return (
    <Box direction="horizontal" gap="8px">
      <TextButton
        size="medium"
        weight={'thin'}
        onClick={() => onVote(ArticleHelpfulState.HELPFUL)}
        dataHook={DATA_HOOKS.HELPFUL_YES}
        className={`${css.textButton} ${
          isPositiveFeedbackSent ? css.selected : ''
        }`}
      >
        {t('helpful.button.yes')}
      </TextButton>
      <Text size="medium" weight="thin" className={css.separator}>
        |
      </Text>
      <TextButton
        size="medium"
        weight={'thin'}
        onClick={() => onVote(ArticleHelpfulState.NOT_HELPFUL)}
        dataHook={DATA_HOOKS.HELPFUL_NO}
        className={`${css.textButton} ${
          isNegativeFeedbackSent ? css.selected : ''
        }`}
      >
        {t('helpful.button.no')}
      </TextButton>
    </Box>
  );
};
