import {
  ArticleAdditionalFeedbackTypeNegative,
  ArticleAdditionalFeedbackTypePositive,
  ArticleHelpfulState,
} from '@wix/answers-api';
import { Box, Button, InputArea } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useState } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import { UnhelpfulOptions } from './UnhelpfulOptions';
import css from './index.module.scss';
import { AdditionalOption } from '..';
import classNames from 'classnames';

const MAX_ADDITIONAL_TEXT_LENGTH = 2000;

export type AdditionalFeedbackProps = {
  voteState: ArticleHelpfulState;
  additionalOption?: AdditionalOption;
  onSubmit: (feedbackText: string) => void;
  onChangeAdditionalOption: (additionalOption: AdditionalOption) => void;
};

export const AdditionalFeedback: FunctionComponent<AdditionalFeedbackProps> = ({
  voteState,
  additionalOption,
  onSubmit,
  onChangeAdditionalOption,
}: AdditionalFeedbackProps) => {
  const { t } = useTranslation();
  const [additionalTextHelpful, setAdditionalTextHelpful] =
    useState<string>('');
  const [additionalTextUnhelpful, setAdditionalTextUnhelpful] =
    useState<string>('');

  const isHelpfulVote = voteState === ArticleHelpfulState.HELPFUL;

  const dataHookAdditionalFeedback = isHelpfulVote
    ? DATA_HOOKS.ADDITIONAL_FEEDBACK_HELPFUL
    : DATA_HOOKS.ADDITIONAL_FEEDBACK_UNHELPFUL;

  const textToSubmit = isHelpfulVote
    ? additionalTextHelpful
    : additionalTextUnhelpful;

  const isMandatoryInputFull =
    additionalOption !== ArticleAdditionalFeedbackTypeNegative.OTHER ||
    additionalTextUnhelpful.length > 0;

  return (
    <Box
      direction="vertical"
      dataHook={DATA_HOOKS.ADDITIONAL_FEEDBACK}
      className={classNames(css.wrapper)}
    >
      <Box
        direction="vertical"
        dataHook={dataHookAdditionalFeedback}
        className={css.additionalFeedback}
      >
        <Box className={css.question}>
          {isHelpfulVote
            ? t('article-feedback.additional.title.helpful')
            : t('article-feedback.additional.title.unhelpful')}
        </Box>
        {isHelpfulVote ? (
          <InputArea
            className={css.helpfulText}
            placeholder={t(
              'article-feedback.additional.text-placeholder.helpful'
            )}
            rows={5}
            size={'small'}
            maxLength={MAX_ADDITIONAL_TEXT_LENGTH}
            resizable
            value={additionalTextHelpful}
            onChange={(e) => setAdditionalTextHelpful(e.target.value)}
          />
        ) : (
          additionalOption !==
            ArticleAdditionalFeedbackTypePositive.HELPFUL && (
            <UnhelpfulOptions
              unhelpfulOption={additionalOption}
              onChangeOption={onChangeAdditionalOption}
            />
          )
        )}

        {voteState === ArticleHelpfulState.NOT_HELPFUL &&
          additionalOption !== undefined && (
            <InputArea
              className={css.unhelpfulText}
              placeholder={t(
                `article-feedback.additional.unhelpful.placeholder.${additionalOption}`
              )}
              dataHook={DATA_HOOKS.ADDITIONAL_UNHELPFUL_TEXT}
              rows={5}
              size={'small'}
              maxLength={MAX_ADDITIONAL_TEXT_LENGTH}
              resizable
              value={additionalTextUnhelpful}
              onChange={(e) => setAdditionalTextUnhelpful(e.target.value)}
            />
          )}

        <Button
          className={css.submitButton}
          dataHook={DATA_HOOKS.SUBMIT_FEEDBACK}
          disabled={
            (voteState === ArticleHelpfulState.NOT_HELPFUL &&
              !isMandatoryInputFull) ||
            !additionalOption
          }
          size="small"
          onClick={() => onSubmit(textToSubmit)}
        >
          {t('article-feedback.submit')}
        </Button>
      </Box>
    </Box>
  );
};
